import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import ReactGA from "react-ga4";

interface StockPlay {
  id: string;
  name: string;
  symbol: string;
  subtext: string;
  imageURL: string;
}

interface PlayProps {
  play: StockPlay;
}

const plays = [
  {
    id: "gme",
    name: "Game Stop",
    symbol: "GME",
    subtext: "Discord Says Ditch",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/game.png",
  },
  {
    id: "anf",
    name: "Abercrombie & Fitch",
    symbol: "ANF",
    subtext: "Exploding on Reddit",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/fitch.png",
  },
  {
    id: "tsla",
    name: "Tesla",
    symbol: "TSLA",
    subtext: "Twitter Says Invest",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/tesla.png",
  },
  {
    id: "msft",
    name: "Microsoft",
    symbol: "MSFT",
    subtext: "$12.7B Invested (1D)",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/micro.png",
  },
  {
    id: "panw",
    name: "Palo Alto Networks",
    symbol: "PANW",
    subtext: "Nancy Pelosi Fav",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/palo.png",
  },
  {
    id: "crwd",
    name: "CrowdStrike",
    symbol: "CRWD",
    subtext: "$127M Invested (1D)",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/crowd.png",
  },
  {
    id: "cl",
    name: "Chainlink",
    symbol: "CL",
    subtext: "Reddit Says Invest",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/chain.png",
  },
  {
    id: "mom",
    name: "Mother of Memes",
    symbol: "MOM",
    subtext: "Cooling Down on Twitter",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/mother.png",
  },
  {
    id: "doge",
    name: "Dogecoin",
    symbol: "DOGE",
    subtext: "Elon is Rallying",
    imageURL:
      "https://storage.googleapis.com/glowupthenblowup.appspot.com/doge.png",
  },
];

const Play: React.FC = () => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const isPaid = localStorage.getItem("hasPaid") === "true";
  const { id } = useParams<{ id: string }>();
  const [play, setPlay] = useState<StockPlay | null>(null);

  useEffect(() => {
    const matchedPlay = plays.find((p) => p.id === id);
    if (matchedPlay) {
      setPlay(matchedPlay);
    }
  }, [id]);

  const handleUnlock = (isMonthly: boolean) => {
    ReactGA.event({
      category: "User",
      action: "Clicked Unlock",
      label: isMonthly ? `Monthly ${id}` : `Annual ${id}`,
    });

    ReactGA.send({ hitType: "pageview", page: `/play/${id}` });

    let isDev = process.env.NODE_ENV === "development";
    if (isDev) {
      window.location.href = "https://buy.stripe.com/test_6oE3cnf8HaGna4g000";
      return;
    }

    const monthlyUrl = "https://buy.stripe.com/00gdQWgk93F7ceI4gs";
    const annualUrl = "https://buy.stripe.com/dR66ou4Brb7z3Ic6oB";
    window.location.href = isMonthly ? monthlyUrl : annualUrl;
  };

  if (!play) {
    return <div></div>;
  }

  const generateRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const sourcesScrapped = generateRandomNumber(5, 20);
  const pagesRead = generateRandomNumber(100, 500);
  const hoursSaved = generateRandomNumber(9, 30);

  return (
    <div className="bg-black text-white min-h-screen font-sans flex justify-center">
      <div className="p-4 max-w-[633px] w-full">
        <div className="mb-6">
          <div className="flex items-center mb-4">
            <img
              src={play.imageURL}
              alt={`${play.name} logo`}
              className="w-20 h-20 rounded-full mr-4"
            />
          </div>
          <h2 className="text-2xl font-bold expanded-bold uppercase">
            {play.name}
          </h2>
        </div>

        <div className="border border-white border-opacity-10 rounded-xl p-4 mb-6 space-y-4">
          <MetricItem
            icon="💻"
            color="bg-purple-600"
            text={`${sourcesScrapped} Sources Scraped`}
          />
          <MetricItem
            icon="🤓"
            color="bg-blue-600"
            text={`${pagesRead} Pages Read`}
          />
          <MetricItem
            icon="🕰️"
            color="bg-green-600"
            text={`${hoursSaved} Hours Saved Researching`}
          />
        </div>

        <div className="bg-white bg-opacity-10 rounded-xl p-6 mb-20">
          <div className="flex items-center mb-2">
            <h3 className="text-2xl font-bold expanded-bold">
              START YOUR 7-DAY FREE TRIAL.
            </h3>
          </div>
          <p className="text-[15px] text-gray-400 mb-6 mt-6 leading-[1.7]">
            Choose one of the options below to unlock the research for{" "}
            {play.name} and all other investments.
          </p>
          <p className="text-[15px] text-gray-400 mb-3 mt-4">
            - Get a Team of AI Analysts Working 24/7
          </p>
          <p className="text-[15px] text-gray-400 mb-3">
            - Save 100s of Hours on Research
          </p>
          <p className="text-[15px] text-gray-400 mb-3">
            - Get Insider Info Every Day
          </p>
          <p className="text-[15px] text-gray-400 mb-3">
            - Daily Picks on What to Buy or Sell
          </p>
          <button
            className="mt-6 bg-white bg-opacity-10 text-white py-5 px-7 rounded-full font-bold w-full text-sm expanded-bold flex justify-between items-center"
            onClick={() => handleUnlock(true)}
          >
            <span className={`text-[17px]`}>$4.99/mo</span>
            <span className="text-s text-white regular">Monthly</span>
          </button>
          <button
            className="mt-6 bg-white text-black py-5 px-7 rounded-full font-bold w-full text-sm expanded-bold flex justify-between items-center"
            style={{
              borderWidth: "0px",
            }}
            onClick={() => handleUnlock(false)}
          >
            <span className={`text-[17px]`}>$2.99/mo</span>
            <span className="text-s text-black regular">
              Save 50% with Yearly
            </span>
          </button>
          <p className="text-center text-[14px] text-gray-400 mt-4">
            Cancel anytime.
          </p>
        </div>
      </div>
    </div>
  );
};

const MetricItem = ({
  icon,
  color,
  text,
}: {
  icon: string;
  color: string;
  text: string;
}) => (
  <div className="flex items-center">
    <div
      className={`w-8 h-8 rounded-full mr-2 text-[20px] flex items-center justify-center text-white`}
    >
      {icon}
    </div>
    <span className={`text-[15px]`}>{text}</span>
  </div>
);

export default Play;
