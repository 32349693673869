import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const Success: React.FC = () => {
  const navigate = useNavigate();
  const [version, setVersion] = React.useState<string>("");

  useEffect(() => {
    const storedVersion = localStorage.getItem("version") || "";
    setVersion(storedVersion);
    if (!storedVersion) {
      console.warn("Version not found in localStorage");
    }

    ReactGA.event({
      category: "User",
      action: "Paid for Glow Up",
      label: storedVersion,
    });

    localStorage.setItem("hasPaid", "true");
    navigate("/glow");
  }, [navigate]);

  return (
    <div className="bg-black text-white min-h-screen flex items-center justify-center"></div>
  );
};

export default Success;
