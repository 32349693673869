import React, { useState } from "react";
import { ChevronDown } from "lucide-react";

const FAQItem = ({
  question,
  children,
}: {
  question: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-200 rounded-md mb-4">
      <button
        className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{question}</span>
        <ChevronDown
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && <div className="p-4 pt-0 text-gray-600">{children}</div>}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    { question: "How does Memz turn my photos into songs?" },
    { question: "What type of photos work best with Memz?" },
    {
      question: "Can I customize the genre or style of the song Memz creates?",
    },
    {
      question:
        "How long does it take for Memz to generate a song from my photos?",
    },
    {
      question:
        "Can I edit or make changes to the song after it's been created?",
    },
    {
      question: "Are there different plans or subscription options for Memz?",
    },
    { question: "Can I try Memz before committing to a subscription?" },
    {
      question:
        "How can I share the songs created by Memz with my friends and family?",
    },
  ];

  const answers = [
    "Memz uses advanced AI technology to analyze your photos and generate unique songs that capture the mood, emotions, and memories associated with those images.",
    "Memz works best with clear, high-quality photos that have strong emotional content or represent significant memories. Group photos, landscapes, and event pictures often yield great results.",
    "Yes, Memz offers options to customize the genre and style of the song. You can select preferences like pop, rock, classical, or electronic to influence the final composition.",
    "The song generation process typically takes a few minutes, depending on the complexity of the photos and the desired musical style. You'll be notified as soon as your unique song is ready.",
    "Currently, Memz doesn't offer direct editing of the generated songs. However, you can always create a new song with different photos or style preferences if you're not satisfied with the result.",
    "Yes, Memz offers various subscription plans to cater to different usage levels. We have monthly and annual options, each with its own set of features and benefits.",
    "Absolutely! Memz offers a free trial where you can create your first song at no cost. This allows you to experience the magic of Memz before subscribing.",
    "Memz makes it easy to share your created songs. You can directly share them via social media, email, or generate a unique link to send to your friends and family.",
  ];

  return (
    <div className="bg-white text-black min-h-screen p-1 mt-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center expanded-bold">
          Frequently Asked Questions
        </h1>
        <p className="text-center mb-8 text-gray-600">
          Contact us at{" "}
          <a
            href="mailto:hello@rendernet.ai"
            className="text-blue-600 hover:underline"
          >
            hello@memzsongs.com{" "}
          </a>
          for any additional queries and concerns
        </p>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question}>
            <p>{answers[index]}</p>
          </FAQItem>
        ))}
      </div>
    </div>
  );
};

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState("monthly");

  const handleUnlock = (type: string) => {
    let isDev = process.env.NODE_ENV === "development";
    if (isDev) {
      window.location.href = "https://buy.stripe.com/test_6oE3cnf8HaGna4g000";
      return;
    }

    const monthlyUrlPro = "https://buy.stripe.com/3cs3cigk9ejL0w07sK";
    const monthlyUrlMax = "https://buy.stripe.com/6oEfZ4fg53F70w08wP";
    const annualUrlPro = "https://buy.stripe.com/3cs4gmc3T8Zr7Ys5kE";
    const annualUrlMax = "https://buy.stripe.com/cN25kqc3Tb7zbaE9AV";
    window.location.href =
      type === "pro"
        ? billingCycle === "monthly"
          ? monthlyUrlPro
          : annualUrlPro
        : billingCycle === "monthly"
        ? monthlyUrlMax
        : annualUrlMax;
  };

  const plans = [
    {
      name: "Pro Plan",
      monthlyPrice: 6,
      annualPrice: 3,
      period: "per month, billed monthly",
      credits: 100,
      creditPeriod: "month",
      parallelGenerations: 10,
      features: [
        "10 AI-generated songs per month",
        "High-quality audio output",
        "Multiple genre options",
      ],
    },
    {
      name: "Max Plan",
      monthlyPrice: 12,
      annualPrice: 6,
      period: "per month, billed monthly",
      credits: "Unlimited",
      creditPeriod: "month",
      parallelGenerations: 50,
      features: [
        "Unlimited AI-generated songs",
        "Premium audio quality",
        "All available music genres",
        "Advanced photo analysis",
        "Extended song duration",
        "Priority processing",
      ],
      isMostBought: true,
    },
  ];

  return (
    <div className="bg-white text-black min-h-screen p-6 pt-8 font-sans">
      <h1 className="text-3xl font-bold text-center mb-8 expanded-bold">
        Start your 7-day free trial. Cancel anytime.
      </h1>

      <div className="flex justify-center mb-8">
        <div className="flex bg-gray-100 rounded-full p-1">
          <button
            className={`px-6 py-2 rounded-full ${
              billingCycle === "monthly" ? "bg-white shadow" : ""
            }`}
            onClick={() => setBillingCycle("monthly")}
          >
            Monthly
          </button>
          <button
            className={`px-6 py-2 rounded-full ${
              billingCycle === "annually" ? "bg-white shadow" : ""
            }`}
            onClick={() => setBillingCycle("annually")}
          >
            Annually
          </button>
          <span className="bg-[#4100F4] text-white px-4 py-2 rounded-full text-sm ml-2">
            50% Off
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className={`bg-white border ${
              plan.name == "Max Plan"
                ? "border-[#4100F4] border-2"
                : "border-gray-200"
            } rounded-lg p-6 flex flex-col relative`}
          >
            {plan.name == "Max Plan" && (
              <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#4100F4] text-white px-4 py-1 rounded-full text-sm">
                Most Bought
              </div>
            )}
            <div className="text-2xl font-bold mb-2 expanded-bold">
              {plan.name}
            </div>
            <div className="text-3xl font-bold mb-1 expanded-bold">
              $
              {billingCycle === "annually"
                ? plan.annualPrice
                : plan.monthlyPrice}
            </div>
            <p className="text-gray-500 mb-4">
              {billingCycle === "annually"
                ? `per month, billed $${plan.annualPrice * 12} yearly`
                : plan.period}
            </p>
            <p className="mb-1">
              <span className="font-bold">{plan.credits}</span> credits/
              {plan.creditPeriod}
            </p>
            <button
              onClick={() =>
                handleUnlock(plan.name === "Pro Plan" ? "pro" : "max")
              }
              className={`py-3 px-4 rounded-lg w-full mb-6 ${"bg-[#4100F4] text-white"}`}
            >
              {"Start Trial"}
            </button>
            <ul className="space-y-2">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center">
                  <span
                    className={`mr-2 ${
                      plan.name === "Free" ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {plan.name === "Free" ? "✕" : "✓"}
                  </span>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="text-center text-gray-500 text-sm mt-8">
        <p className="mt-2">
            10 credits = 1 song.
        </p>
        <p className="mt-2">
          You can cancel your subscription anytime. You'll still be able to use
          your plan until the end of your current billing period.
        </p>
        <p className="mt-2">
          To cancel, click 'Manage subscription' on the Account screen.
        </p>
      </div>
      <FAQSection />
    </div>
  );
};

export default PricingPage;
