import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useAuth0 } from "@auth0/auth0-react";
import { assignVersion } from "../utils/utils";
import { FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ChevronDown } from "lucide-react";

const TopSection = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  return (
    <div className="bg-[#fff] p-6 mt-[-1px]">
      <div className="space-y-8 text-center p-3 pt-[20px] bg-[#fff]">
        <h2
          className="expanded-bold"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Turn your memories into songs with AI.
          <br />
        </h2>

        <button
          onClick={onClick}
          className="bg-[#4100F4] text-white px-6 py-5 rounded-full text-[15px] w-full flex items-center justify-center space-x-2 expanded-bold"
        >
          <span>Get First Song</span>
          <span className="text-gray-400"></span>
        </button>

        <div className="flex items-center justify-center space-x-2">
          <div className="flex -space-x-2">
            <img
              src="https://storage.googleapis.com/glowupthenblowup.appspot.com/woman_1.png"
              alt="User 1"
              className="rounded-full border-2 w-8 h-8"
            />
            <img
              src="https://storage.googleapis.com/glowupthenblowup.appspot.com/person2.png"
              alt="User 2"
              className="rounded-full border-2 w-8 h-8"
            />
            <img
              src="https://storage.googleapis.com/glowupthenblowup.appspot.com/woman_2.png"
              alt="User 3"
              className="rounded-full border-2 w-8 h-8"
            />
          </div>
          <span className="text-[14px] regular">
            Loved by over 1 million people
          </span>
        </div>

        <p className="text-[14px] regular">
          “Memz honestly made me cry. It's like if Taylor Swift wrote a song
          based on my photos. Instantly. It's incredible.” -{" "}
          <b>@paigeuncaged</b>
        </p>
      </div>

      <div className="mt-8 pb-8 px-2">
        <div className="max-w-[326px] mx-auto">
          <img
            src="https://storage.googleapis.com/memzai.appspot.com/mem1-min.png"
            alt="Kitty Cash"
            className="w-full h-auto rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

const Section1 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  return (
    <div className="font-sans">
      {/* Blue section */}
      <div className="bg-[#fff] p-6 pt-6 text-black text-center">
        <h2
          className="font-bold mb-8 leading-tight expanded-bold"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Upload Photos
        </h2>

        <img
          src="https://storage.googleapis.com/memzai.appspot.com/mem2-min.png"
          className="w-318 mb-2 mt-6"
        />

        <p className="text-[14px] mb-6 mt-8 regular">
          All you need to do is a upload photos. It could be a trip to Tulum or
          San Francisco, your daughters birthday or just fun with friends.
        </p>
      </div>
    </div>
  );
};

const Section2 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="font-sans text-black">
      <div className="bg-[#fff] p-6 pt-6 text-center">
        <h2
          className="font-bold mb-8 leading-tight expanded-bold"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Memz AI will write lyrics and create a song based on your photos
        </h2>

        {/* Audio player component */}
        <div className="bg-[#F3F0FF] rounded-lg p-4 mb-6 mt-8 flex items-center">
          <button
            onClick={togglePlay}
            className="bg-[#4100F4] text-white w-10 h-10 rounded-full text-[14px] expanded-bold flex items-center justify-center mr-4"
          >
            <span className="flex items-center justify-center w-full h-full">
              {isPlaying ? "⏸" : "▶"}
            </span>
          </button>
          <p className="text-[14px] regular">Trip to Tulum Song</p>
          <audio
            ref={audioRef}
            src="https://storage.googleapis.com/memzai.appspot.com/Peso%20Pluma%2C%20Grupo%20Frontera%20-%20TULUM%20(Video%20Oficial).mp3"
          />
        </div>

        <p className="text-[14px] mb-6 mt-8 regular">
          Memz AI analyzes your photos and creates custom songs that capture the
          essence of your memories, turning visual moments into musical
          experiences.
        </p>
      </div>
    </div>
  );
};

const Section3 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  return (
    <div className="font-sans text-black">
      {/* Black section */}
      <div className="bg-white p-8 pt-12 pb-8 text-center">
        <h2
          className="font-bold mb-8 leading-tight expanded-bold"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Get a soundtrack to <br /> your life.
        </h2>

        {/* Sign Up button */}
        <button
          onClick={onClick}
          className="bg-[#4100F4] text-white font-bold py-3 px-8 rounded-full text-[15px] mb-8 expanded-bold"
        >
          Sign Up <span className="text-gray-500"></span>
        </button>
      </div>
    </div>
  );
};

const Section4 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  const testimonials = [
    {
      name: "Zach Nelson",
      handle: "@zachnelso19",
      date: "18 June",
      content:
        "Memz is absolutely incredible! I uploaded photos from my recent road trip, and the AI-generated song captured the essence of our adventure perfectly. The melody brought back all the emotions and memories from those moments. It's like having a personal soundtrack to my life experiences. Highly recommend!",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/zach.jpg",
    },
    {
      name: "Luke Gilford",
      handle: "@lukegilford",
      date: "23 June",
      content:
        "I was skeptical at first, but Memz blew me away. I used it for my wedding photos, and the song it created was so beautiful and touching. It perfectly encapsulated the joy and love of that day. Now we have a unique musical memento of our special day. It's become our new favorite song!",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/luke.jpg",
    },
    {
      name: "Sabrina Mayes",
      handle: "@sabrina___",
      date: "20 Mar",
      content:
        "Memz has added a whole new dimension to my travel memories. I used it with photos from my trip to Japan, and the song it created was infused with elements of traditional Japanese music. It's like I can close my eyes, listen to the song, and be transported back to those moments. Such a unique and powerful way to preserve memories!",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/sabrina.jpg",
    },
    {
      name: "Lucy Hormozi",
      handle: "@lhormozi19",
      date: "1 Jul",
      content:
        "As a professional photographer, I'm always looking for new ways to present my work. Memz has become an invaluable tool in my kit. I can now offer clients not just photos, but also a custom song that captures the mood of their photoshoot. It's been a game-changer for my business and my clients absolutely love it!",
      imageURL:
        "https://storage.googleapis.com/glowupthenblowup.appspot.com/lucy.jpg",
    },
  ];

  return (
    <div className="bg-[#fff] text-black min-h-screen p-8 font-sans">
      <div className="max-w-2xl mx-auto">
        <h2
          className="font-bold mb-8 leading-tight expanded-bold text-center"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Join 1 million people with a new soundtrack to their life 
        </h2>
        <p className="text-[14px] mb-8 text-center regular">
          Memz has helped over 1 million people turn their memories into songs.
        </p>
        <div className="bg-white rounded-lg p-4 shadow-lg mb-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="mb-6 last:mb-0">
              <div className="flex items-center text-gray-600 text-sm">
                <img
                  src={testimonial.imageURL}
                  alt={testimonial.name}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <span className="font-bold">{testimonial.name}</span>
                <span className="ml-2">{testimonial.handle}</span>
                {/* <span className="ml-2">· {testimonial.date}</span> */}
              </div>
              <p className="mt-1 text-gray-800">{testimonial.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Section7 = ({
  screenSize,
  onClick,
}: {
  screenSize: number;
  onClick: () => void;
}) => {
  return (
    <div className="font-sans text-black">
      {/* Black section */}
      <div className="bg-white p-6 pt-6 text-center">
        <h2
          className="font-bold mb-8 leading-tight expanded-bold"
          style={{
            fontSize: screenSize < 414 ? "25px" : "28px",
          }}
        >
          Try Memz for free today.
        </h2>

        {/* Sign Up button */}
        <button
          onClick={onClick}
          className="bg-[#4100F4] text-white font-bold py-3 px-8 rounded-full text-[14px] mb-8 expanded-bold"
        >
          Get First Song <span className="text-gray-500"></span>
        </button>
      </div>
    </div>
  );
};

const FAQItem = ({
  question,
  children,
}: {
  question: string;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-200 rounded-md mb-4">
      <button
        className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{question}</span>
        <ChevronDown
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && <div className="p-4 pt-0 text-gray-600">{children}</div>}
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    { question: "How does Memz turn my photos into songs?" },
    { question: "What type of photos work best with Memz?" },
    {
      question: "Can I customize the genre or style of the song Memz creates?",
    },
    {
      question:
        "How long does it take for Memz to generate a song from my photos?",
    },
    {
      question:
        "Can I edit or make changes to the song after it's been created?",
    },
    {
      question: "Are there different plans or subscription options for Memz?",
    },
    { question: "Can I try Memz before committing to a subscription?" },
    {
      question:
        "How can I share the songs created by Memz with my friends and family?",
    },
  ];

  const answers = [
    "Memz uses advanced AI technology to analyze your photos and generate unique songs that capture the mood, emotions, and memories associated with those images.",
    "Memz works best with clear, high-quality photos that have strong emotional content or represent significant memories. Group photos, landscapes, and event pictures often yield great results.",
    "Yes, Memz offers options to customize the genre and style of the song. You can select preferences like pop, rock, classical, or electronic to influence the final composition.",
    "The song generation process typically takes a few minutes, depending on the complexity of the photos and the desired musical style. You'll be notified as soon as your unique song is ready.",
    "Currently, Memz doesn't offer direct editing of the generated songs. However, you can always create a new song with different photos or style preferences if you're not satisfied with the result.",
    "Yes, Memz offers various subscription plans to cater to different usage levels. We have monthly and annual options, each with its own set of features and benefits.",
    "Absolutely! Memz offers a free trial where you can create your first song at no cost. This allows you to experience the magic of Memz before subscribing.",
    "Memz makes it easy to share your created songs. You can directly share them via social media, email, or generate a unique link to send to your friends and family.",
  ];

  return (
    <div className="bg-white text-black min-h-screen p-6">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center expanded-bold">
          Frequently Asked Questions
        </h1>
        <p className="text-center mb-8 text-gray-600">
          Contact us at{" "}
          <a
            href="mailto:hello@rendernet.ai"
            className="text-blue-600 hover:underline"
          >
            hello@memzsongs.com{" "}
          </a>
          for any additional queries and concerns
        </p>
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question}>
            <p>{answers[index]}</p>
          </FAQItem>
        ))}
      </div>
    </div>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isPaid = localStorage.getItem("hasPaid") === "true";
  const [version, setVersion] = useState<string>("");
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    // Check if userId exists in localStorage
    let userId = localStorage.getItem("userId");

    // If userId doesn't exist, create a new one
    if (!userId) {
      userId = generateUserId();
      localStorage.setItem("userId", userId);
      localStorage.setItem("hasPaid", "false");
    }

    const version = assignVersion();
    setVersion(version);
    localStorage.setItem("version", version);

    // Add event listener for window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const generateUserId = () => {
    // Generate a random userId (you can use any method you prefer)
    return Math.random().toString(36).substr(2, 9);
  };

  const { loginWithRedirect } = useAuth0();

  const handleClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Sign Up",
    });

    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <div className="bg-white min-h-screen font-sans text-black">
      <div className="max-w-md mx-auto">
        {/* Header */}
        <div className="flex justify-between p-5 items-center bg-[#fff]">
          <div className="flex items-center space-x-2">
            <img
              src="https://storage.googleapis.com/memzai.appspot.com/memzlogo.jpg"
              alt=""
              className={`mr-2 ${
                screenSize < 414 ? "w-[44px] h-[44px]" : "w-[51px] h-[51px]"
              }`}
            />
            <h1
              className="font-bold expanded-bold"
              style={{
                fontSize: screenSize < 414 ? "22px" : "25px",
              }}
            >
              Memz
            </h1>
          </div>
          <button
            onClick={handleClick}
            className="bg-[#4100F4] text-white px-4 py-2 rounded-full text-[13px] expanded-bold"
          >
            Sign Up
          </button>
        </div>

        <TopSection screenSize={screenSize} onClick={handleClick} />
        <Section1 screenSize={screenSize} onClick={handleClick} />
        <Section2 screenSize={screenSize} onClick={handleClick} />
        <Section3 screenSize={screenSize} onClick={handleClick} />
        <Section4 screenSize={screenSize} onClick={handleClick} />
        <Section7 screenSize={screenSize} onClick={handleClick} />
        <FAQSection />

        {/* <div className="flex justify-center p-4 pt-2 pb-2 space-x-8 mb-6 mt-4">
          <a
            href="https://www.instagram.com/RobinhoodApp/"
            className="w-20 h-20 rounded-full flex items-center justify-center"
          >
            <FaInstagram className="text-white text-3xl" />
          </a>
          <a
            href="https://www.facebook.com/RobinhoodApp/"
            className="w-20 h-20 rounded-full flex items-center justify-center"
          >
            <FaFacebookF className="text-white text-3xl" />
          </a>
          <a
            href="https://twitter.com/RobinhoodApp"
            className="w-20 h-20 rounded-full flex items-center justify-center"
          >
            <FaXTwitter className="text-white text-3xl" />
          </a>
        </div> */}
        <p className="text-sm text-gray-500 text-center p-8">
          Copyright 2024 Memz. All rights reserved.
          <br />
        </p>
      </div>
    </div>
  );
};

export default Landing;
