import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Success: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('hasPaid', 'false');
    navigate('/glow');
  }, [navigate]);

  return (
    <div className="bg-black text-white min-h-screen flex items-center justify-center">
    </div>
  );
};

export default Success;
