import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import Glow from "./pages/Glow";
import Play from "./pages/Play";
import Upload from "./pages/Upload";
import Landing from "./pages/Landing";
import RootdLanding from "./pages/RootdLanding";
import MemzLanding from "./pages/MemzLanding";
import MemzPricing from "./pages/MemzPricing";
import Pricing from "./pages/Pricing";
import ReactGA from "react-ga4";

ReactGA.initialize("G-FB9WJV50TM");

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-white text-black">
        <main className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                process.env.WHICH_APP === "memz" ? (
                  <MemzLanding />
                ) : (
                  <MemzLanding />
                )
              }
            />
            <Route
              path="/home"
              element={
                process.env.WHICH_APP === "memz" ? (
                  <MemzLanding />
                ) : (
                  <MemzLanding />
                )
              }
            />
            <Route
              path="/pricing"
              element={
                process.env.WHICH_APP === "memz" ? <MemzPricing /> : <MemzPricing />
              }
            />
            <Route path="/play/:id" element={<Play />} />
            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
