import ReactGA from "react-ga4";

const handleFileChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  navigate: (path: string) => void,
  abVersion: string
) => {
  const files = event.target.files;
  if (files && files.length > 0) {
    const formData = new FormData();

    formData.append("images", files[0]);

    const userId = localStorage.getItem("userId");
    if (!userId) {
      console.error("User ID not found in localStorage");
      return;
    }

    formData.append("userId", userId);
    formData.append("abVersion", abVersion);

    console.log(`File name:`, files[0].name);

    try {
      ReactGA.event({
        category: "User",
        action: "Clicked Upload Selfies Button",
        label: abVersion,
      });

      let isDev = process.env.NODE_ENV === "development";
      let url = isDev
        ? "http://localhost:3001/api/glow-up"
        : "https://supermall-shazam-api.uc.r.appspot.com/api/glow-up";

      const glowUpId = generateUniqueId(); // Assuming a function to generate a unique ID
      formData.append("glowUpId", glowUpId);

      const response = fetch(url, {
        method: "POST",
        body: formData,
      });

      navigate(`/glow/${glowUpId}`);

      console.log("Image uploaded successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
};

const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

function assignVersion(): string {
  const versions = ["A", "B", "C"];
  const randomIndex = Math.floor(Math.random() * versions.length);
  return versions[randomIndex];
}

export { handleFileChange, generateUniqueId, assignVersion };
